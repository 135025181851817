<template>
  <contato />
</template>

<script>
import Contato from '../components/Contato.vue';

export default {
  name: 'Home',

  components: {
    Contato,
  },
};
</script>
