<template>
  <v-container fluid>
    <div id="app">
      <v-app>
        <v-card color="#0040a1">
          <v-card-title class="font-weight 0040a1 white--text"
            >Encontre um Vendedor</v-card-title
          >
          <v-card-subtitle class="body-2 amber--text"
            >Selecione a cidade e clique no nome de um dos vendedores para ser
            atendido via whatsapp. Atendimento de Segunda à Sexta-feira, das 8h
            às 18h.</v-card-subtitle
          >

          <v-card-text>
            <v-autocomplete
              dark
              v-model="model"
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              color="white"
              hide-no-data
              hide-selected
              item-text="cidade"
              item-value="API"
              clearable
              label="Nome da Cidade"
              placeholder="Digite sua cidade"
              :prepend-icon="svgMap"
              return-object
            ></v-autocomplete>
          </v-card-text>
          <v-divider></v-divider>
          <v-card color="white">
            <v-list three-line v-if="model">
              <v-list-item-group active-class="primary--text">
                <template v-for="(field, i) in fields">
                  <v-list-item
                    @click="menu = false"
                    :href="
                      apilink +
                        field.fone +
                        '&text=' +
                        text1 +
                        field.nome +
                        text2
                    "
                    target="_blank"
                    :key="field.nome"
                  >
                    <v-list-item-avatar>
                      <v-icon color="green">{{ svgPath }}</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        class="text--primary"
                        v-html="field.nome"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        class="text--primary"
                        v-html="field.fone"
                      ></v-list-item-subtitle>
                      <v-list-item-title
                        class="text--primary"
                        v-html="field.ende"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider
                    color="white"
                    v-if="i + 1 < fields.length"
                    :key="i"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!model" color="primary" @click="model = null">
              Limpar
              <v-icon right>mdi-close-circle</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-app>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios';
import { mdiWhatsapp, mdiMapMarkerOutline } from '@mdi/js';

export default {
  data: () => ({
    svgPath: mdiWhatsapp,
    svgMap: mdiMapMarkerOutline,
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
    search: null,
    apilink: null,
    text1: null,
    text2: null,
  }),

  computed: {
    fields() {
      if (!this.model) return [];
      return Object.keys(this.model.vendedores).map((key) => {
        return {
          key,
          nome: this.model.vendedores[key].nome,
          fone: this.model.vendedores[key].fone,
          ende: this.model.vendedores[key].endereco,
        };
      });
    },
    items() {
      return this.entries.map((entry) => {
        const Description =
          entry.cidade.length > this.descriptionLimit
            ? entry.cidade.slice(0, this.descriptionLimit) + '...'
            : entry.cidade;
        return Object.assign({}, entry, { Description });
      });
    },
  },
  created: function() {
    this.$getLocation({
      enableHighAccuracy: false, //defaults to false
      timeout: Infinity, //defaults to Infinity
      maximumAge: 0, //defaults to 0
    }).then((coordinates) => {
      this.getStreetAddressFrom(coordinates.lat, coordinates.lng);
    });

    fetch('https://apicontato.lojasbecker.com/filiais')
      .then((res) => res.json())
      .then((res) => {
        this.entries = res;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => (this.isLoading = false));

    this.text1 = encodeURI('Olá! ');
    this.text2 = encodeURI(
      ', Gostaria de informações sobre os produtos da Lojas Becker. Pode me ajudar?'
    );
    this.apilink = 'http://';
    this.apilink += this.isMobile() ? 'api' : 'web';
    this.apilink += '.whatsapp.com/send?phone=+550';
  },
  mounted() {},

  watch: {
    search() {
      // Items have already been loaded
      if (this.items.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      fetch('https://apicontato.lojasbecker.com/filiais')
        .then((res) => res.json())
        .then((res) => {
          this.entries = res;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  methods: {
    isMobile() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        return true;
      } else {
        return false;
      }
    },

    getPlaceData(place, infoType, format) {
      let data,
        addressComponents = [...place.address_components];

      addressComponents.forEach((componentItem) => {
        if (componentItem.types[0] === infoType) {
          data = componentItem[format];
          addressComponents.length = 0;
        }
      });

      return data;
    },

    async getStreetAddressFrom(lat, long) {
      try {
        var { data } = await axios.get(
          'https://apicontato.lojasbecker.com/geolocation/' + lat + ',' + long
        );
        if (data.error_message) {
          console.log(data.error_message);
        } else {
          this.address = data.results[0].formatted_address;
          const city =
            this.getPlaceData(data.results[0], 'locality', 'long_name') ||
            this.getPlaceData(
              data.results[0],
              'administrative_area_level_2',
              'long_name'
            );
          const state = this.getPlaceData(
            data.results[0],
            'administrative_area_level_1',
            'short_name'
          );

          const position = (city + ', ' + state).toUpperCase();
          this.model = this.entries.find((cidade) => cidade.cidade == position);
        }
      } catch (error) {
        console.log(error.message);
      }
    },
  },
};
</script>
