<template>
  <v-app>
    <v-app-bar app color="indigo">
      <div class="d-flex align-center">
        <v-img
          alt="Becker Logo"
          contain
          src="@/assets/logo-lojas-becker.png"
          transition="scale-transition"
          width="150"
        />
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
